{
  "name": "sportyano",
  "version": "0.0.0",
  "version_dev": "0.0.0",
  "version_stg": "4.5.0",
  "version_prod": "4.4.0",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "build": "ng build",
    "watch": "ng build --watch --configuration development",
    "update-tests": "node update-test-config.js",
    "test": "npm run update-tests && ng test",
    "serve:ssr:Sportyano": "node dist/sportyano/server/server.mjs"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^17.1.0",
    "@angular/cdk": "^17.3.10",
    "@angular/common": "^17.1.0",
    "@angular/compiler": "^17.1.0",
    "@angular/core": "^17.1.0",
    "@angular/fire": "^17.0.1",
    "@angular/forms": "^17.1.0",
    "@angular/material": "^17.1.0",
    "@angular/platform-browser": "^17.1.0",
    "@angular/platform-browser-dynamic": "^17.1.0",
    "@angular/platform-server": "^17.1.0",
    "@angular/router": "^17.1.0",
    "@angular/ssr": "^17.1.1",
    "@angular/youtube-player": "^17.3.10",
    "@emailjs/browser": "^4.3.3",
    "@ngx-loading-bar/core": "^6.0.2",
    "@ngx-loading-bar/http-client": "^6.0.2",
    "@ngx-translate/core": "^15.0.0",
    "@ngx-translate/http-loader": "^8.0.0",
    "express": "^4.18.2",
    "file-saver": "^2.0.5",
    "firebase": "^10.8.0",
    "flowbite": "^2.2.1",
    "google-libphonenumber": "^3.2.34",
    "intl-tel-input": "^19.5.6",
    "leaflet": "^1.9.4",
    "localstorage-polyfill": "^1.0.1",
    "ng-otp-input": "^1.9.3",
    "ng-recaptcha": "^13.2.1",
    "ngx-extended-pdf-viewer": "^21.1.0",
    "ngx-google-analytics": "^14.0.1",
    "ngx-image-cropper": "^7.2.1",
    "ngx-lottie": "^11.0.2",
    "ngx-spinner": "^16.0.2",
    "postcss-import": "^16.0.0",
    "primeicons": "^7.0.0",
    "primeng": "^17.3.3",
    "rxjs": "~7.8.0",
    "tslib": "^2.3.0",
    "xlsx": "^0.18.5",
    "zone.js": "~0.14.3"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^17.1.0",
    "@angular/cli": "^17.1.0",
    "@angular/compiler-cli": "^17.1.0",
    "@types/cookie-parser": "^1.4.6",
    "@types/express": "^4.17.17",
    "@types/file-saver": "^2.0.7",
    "@types/google-libphonenumber": "^7.4.30",
    "@types/intl-tel-input": "^18.1.4",
    "@types/jasmine": "~5.1.0",
    "@types/leaflet": "^1.9.12",
    "@types/node": "^18.18.0",
    "@typescript-eslint/eslint-plugin": "^6.21.0",
    "@typescript-eslint/parser": "^6.21.0",
    "autoprefixer": "^10.4.17",
    "eslint": "^8.56.0",
    "jasmine-core": "~5.1.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "postcss": "^8.4.33",
    "tailwindcss": "^3.4.1",
    "typescript": "~5.3.2"
  }
}
